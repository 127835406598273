import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  title = 'BAV A. Ziselsberger GmbH Transporte & Netzservice';

  constructor(private config: PrimeNGConfig /*, private translateService: TranslateService*/) {}

  ngOnInit(): void {
    // this.translateService.setDefaultLang('de');
    this.config.setTranslation({
      accept: 'Übernehmen',
      reject: 'Abbrechen',
      choose: 'Auswählen',
      dayNames: [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Feitag',
        'Samstag'
      ],
      dayNamesShort: [
        'Son',
        'Mon',
        'Die',
        'Mit',
        'Don',
        'Fre',
        'Sam'
      ],
      dayNamesMin: [
        'So',
        'Mo',
        'Di',
        'Mi',
        'Do',
        'Fr',
        'Sa'
      ],
      monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
      ],
      monthNamesShort: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez'
      ],
      today: 'Heute',
      weekHeader: 'Wo'
    });
  }

  // translate(lang: string): void {
  //   this.translateService.use(lang);
  //   this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  // }

}
