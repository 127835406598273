export function fullName(person: any): any {
  const firstName = person.firstName ? person.firstName : '-';
  const lastName = person.lastName ? person.lastName : '-';
  person.fullName = `${firstName} ${lastName}`;
  return person;
}

export class PersonHelper {


}
