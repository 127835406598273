import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {PopupHelper} from '../popup.helper';

@Component({
  selector: 'app-popup-footer',
  templateUrl: './popup-footer.component.html',
  styleUrls: ['./popup-footer.component.css']
})
export class PopupFooterComponent implements OnInit {

  @Input() popupHelper: PopupHelper;
  @Output() save = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  saveClicked(event: any): void {
    this.save.emit(event);
  }

}
