export function applyInactiveMapping(list: any[]): void {
  list.map((item: any) => item.isInactive = !!item.deactivatedOn);
}

export function applyInactiveFlag(item: any): void {
  if (item.isInactive) {
    item.deactivatedOn = new Date();
  } else {
    item.deactivatedOn = null;
  }
}

export class SettingsHelper {
}
