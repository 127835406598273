import { Injectable } from '@angular/core';
import { UserService } from '../../dashboard/user/user.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { GlobalConstants } from '../../shared/models/global.constants';
import decode from 'jwt-decode';

export enum PermissionType {
  CAN_ADD_DEBITORS = 'CAN_ADD_DEBITORS',
  CAN_EDIT_DEBITORS = 'CAN_EDIT_DEBITORS',
  CAN_ADD_CONSTRUCTION_FILES = 'CAN_ADD_CONSTRUCTION_FILES',
  IS_CONSTRUCTION_FILE_ADMIN = 'IS_CONSTRUCTION_FILE_ADMIN',
  CAN_SEE_TOTAL_AMOUNT = 'CAN_SEE_TOTAL_AMOUNT',
  CAN_SEE_HISTORY = 'CAN_SEE_HISTORY',
  IS_ADMIN = 'IS_ADMIN',
  CAN_MODIFY_MATERIALS = 'CAN_MODIFY_MATERIALS',
  MOBILE = 'MOBILE'
}

export const permissionTypes = [PermissionType.CAN_ADD_DEBITORS, PermissionType.CAN_EDIT_DEBITORS,
                                PermissionType.CAN_ADD_CONSTRUCTION_FILES, PermissionType.IS_CONSTRUCTION_FILE_ADMIN,
                                PermissionType.CAN_SEE_TOTAL_AMOUNT, PermissionType.CAN_SEE_HISTORY, PermissionType.IS_ADMIN, PermissionType.MOBILE, PermissionType.CAN_MODIFY_MATERIALS];

export const constructionFilePermissionTypes = [PermissionType.CAN_ADD_CONSTRUCTION_FILES, PermissionType.IS_CONSTRUCTION_FILE_ADMIN,
                                PermissionType.CAN_SEE_TOTAL_AMOUNT, PermissionType.CAN_SEE_HISTORY, PermissionType.IS_ADMIN, PermissionType.CAN_MODIFY_MATERIALS];

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  permissions: any[]; // TODO: permissions to lacal storage

  constructor(private userService: UserService, private router: Router) {
    this.permissions = JSON.parse(localStorage.getItem('bcx-permissions'));
  }

  loadPermissions(tokenObj: any): any[] {
    this.permissions = [];
    if(tokenObj.CanAddConstructionFiles) {
      this.permissions.push(PermissionType.CAN_ADD_CONSTRUCTION_FILES);
    }
    if(tokenObj.CanAddDebitors) {
      this.permissions.push(PermissionType.CAN_ADD_DEBITORS);
    }
    if(tokenObj.CanEditDebitors) {
      this.permissions.push(PermissionType.CAN_EDIT_DEBITORS);
    }
    if(tokenObj.CanSeeHistory) {
      this.permissions.push(PermissionType.CAN_SEE_HISTORY);
    }
    if(tokenObj.CanSeeTotalAmount) {
      this.permissions.push(PermissionType.CAN_SEE_TOTAL_AMOUNT);
    }
    if(tokenObj.IsAdmin) {
      this.permissions.push(PermissionType.IS_ADMIN);
    }
    if(tokenObj.IsConstructionFileAdmin) {
      this.permissions.push(PermissionType.IS_CONSTRUCTION_FILE_ADMIN);
    }
    if(tokenObj.CanModifyMaterials) {
      this.permissions.push(PermissionType.CAN_MODIFY_MATERIALS);
    }
    if(tokenObj.Mobile) {
      this.permissions.push(PermissionType.MOBILE)
    }
    return this.permissions;
  }

  getCurrentPermissions(): any {
    if(!this.permissions) {
      this.loadPermissions(this.getTokenObj());

      if(!this.permissions) {
        this.router.navigate(['login']);
        return [];
      }
    }

    return this.permissions;
  }

  isGranted(permissionType: string): boolean {
    const currentPermissions: string[] = this.getCurrentPermissions();
    if (currentPermissions.includes(PermissionType.IS_ADMIN)) {
      return true;
    }

    let permissionsInType = permissionType.split(' || ');
    return permissionsInType.some(type => currentPermissions.includes(type.toUpperCase()));
  }

  isGrantedAny(): boolean {
    return this.permissions.length > 0;
  }

  isGrantedConstructionFiles(): boolean {
    const granted = constructionFilePermissionTypes.filter(type => this.isGranted(type));
    return !!granted.length;
  }

  isAdmin(): boolean {
    return this.isGranted(PermissionType.IS_ADMIN);
  }

  public getToken(): string {
    return localStorage.getItem(GlobalConstants.JWT);
  }

  public getTokenObj(): object {
    return decode(this.getToken());
  }
}
