import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lookupUserName'
})
export class LookupUserNamePipe implements PipeTransform {

  transform(value: string, users: any[]): string {
    if (!users) {
      return '-';
    }
    const result = users.filter((user: any) => user.userId === value);
    if (!result || !result.length) {
      return '-';
    }
    return result[0].firstName + ' ' + result[0].lastName;
  }

}
