import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  constructor(private authService: AuthService) { }
  username = JSON.parse(localStorage.getItem('current-user'));
  menuActive = true;
  items: any[];

  ngOnInit(): void {
    this.items = [
      {
        value: 'Logout',
      },
    ];
  }

  onMenuButtonClick(): void {
    this.menuActive = !this.menuActive;
  }

  onMaskClick(): void {
    this.menuActive = false;
  }

  logout(): void {
    this.authService.logout();
  }
}
