import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionsService, PermissionType} from './permissions.service';

@Directive({
  selector: '[appGranted]'
})
export class GrantedDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionsService
  ) { }

  @Input() set appGranted(permission: string) {
    try {
      if (this.permissionsService.isAdmin() || this.permissionsService.isGranted(permission)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    } catch (error) {
      this.viewContainer.clear();
    }
  }

}
