import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lookupDebitorName'
})
export class LookupDebitorNamePipe implements PipeTransform {

  transform(value: string, debitors: any[]): string {
    if (!debitors) {
      return '-';
    }
    const result = debitors.filter((debitor: any) => debitor.debitorId === value);
    if (!result || !result.length) {
      return '-';
    }
    return result[0].company;
  }

}
