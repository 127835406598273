import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lookupStateName'
})
export class LookupStateNamePipe implements PipeTransform {

  transform(value: string, states: any[]): string {
    if (!states) {
      return '-';
    }
    const result = states.filter((state: any) => state.constructionFileStateId === value);
    if (!result || !result.length) {
      return '-';
    }
    return result[0].label;
  }

}
