import { Injectable } from '@angular/core';
import {api} from '../../shared/models/global.constants';
import {HttpClient} from '@angular/common/http';
import {fullName} from '../../shared/tools/person-helper';
import {applyInactiveFlag, applyInactiveMapping} from '../../shared/tools/settings-helper';

const url = api('Users');

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {}

  async load(onlyActive: boolean): Promise<any> {
    try {
      const users = await this.http.get<any[]>(`${url}/All/${onlyActive}`).toPromise();
      users.map((user: any) => {
        fullName(user);
        user.isInactive = !!user.deactivatedOn;
      });
      return users;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async create(user: any): Promise<any> {
    try {
      const response: any = await this.http.post(url, user).toPromise();
      if (response) {
        user.userId = response.userId;
        return user;
      }
      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async update(user: any): Promise<any> {
    try {
      applyInactiveFlag(user);
      const response = await this.http.put(`${url}/${user.userId}`, user).toPromise();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async delete(user: any): Promise<any> {
    try {
      return await this.http.delete(`${url}/${user.userId}`).toPromise();
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async loadById(id: string): Promise<any> {
    try {
      return await this.http.get(`${url}/${id}`).toPromise();
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async changeEmail(body: { value: string }, id: string): Promise<any> {
    try {
      return await this.http.patch(`${url}/${id}/Email`, body).toPromise();
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async changePassword(body: { value: string }, id: string): Promise<any> {
    try {
      return await this.http.patch(`${url}/${id}/Password`, body).toPromise();
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async checkTokenValidity(body: { value: string }): Promise<any> {
    try {
      return await this.http.post(`${url}/CheckTokenValidity`, body).toPromise();
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async loadPermissions(onlyActive: boolean): Promise<any> {
    try {
      let permissions = await this.http.get<any>(`${url}/Permissions/${onlyActive}`).toPromise();
      applyInactiveMapping(permissions);
      return permissions;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async requestApiKey(body: { expiresAfterDays: number }, id: string): Promise<any> {
    try {
      return await this.http.post(`${url}/${id}/RequestApiKay`, body).toPromise();
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
