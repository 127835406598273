import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { api, GlobalConstants } from '../shared/models/global.constants';
import { PermissionsService } from './permissions/permissions.service';
import decode from 'jwt-decode';

const url = api('Users');

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  cachedRequests: Array<HttpRequest<any>> = [];

  constructor(
    private router: Router,
    private jwtHelper: JwtHelperService,
    private http: HttpClient,
    private permissionsService: PermissionsService
  ) {
  }

  async authenticate(credentials: { email: string, password: string, clientId: string }): Promise<boolean> {
    try {
      const result: any = await this.http.post(`${url}/Authenticate`, credentials).toPromise();
      if (result.token) {
        localStorage.setItem('current-user', JSON.stringify(credentials.email));
        localStorage.setItem('client-id', JSON.stringify(credentials.clientId));
        this.applyToken(result.token);
        this.permissionsService.loadPermissions(this.getTokenObj());
        return true;
      }
      console.log('login failed: ' + credentials.email);
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  applyToken(token: string): void {
    localStorage.setItem(GlobalConstants.JWT, token);
  }

  async logout(): Promise<any> {
    try {
      const token = this.getToken();
      if (token) {
        const body = { value: token };
        await this.http.put(`${url}/Logout`, body).toPromise();
        localStorage.removeItem(GlobalConstants.JWT);
      }
      localStorage.clear();
      return await this.router.navigate(['/login']);
    } catch (e) {
      return await this.router.navigate(['/login']);
    }
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem(GlobalConstants.JWT));
    return (user !== undefined && user !== '' && user !== null);
  }

  public getToken(): string {
    return localStorage.getItem(GlobalConstants.JWT);
  }

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  public isAuthenticated(): boolean {
    try {
      const token = localStorage.getItem(GlobalConstants.JWT);
      return !this.jwtHelper.isTokenExpired(token);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public getTokenObj(): object {
    return decode(this.getToken());
  }
}
