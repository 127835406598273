import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MenuModule } from 'primeng/menu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { CommonModule } from '@angular/common';
import { PopupFooterComponent } from './popup/popup-footer/popup-footer.component';
import { DialogModule } from 'primeng/dialog';
import { LookupUserNamePipe } from './tools/lookup-user-name.pipe';
import { LookupDebitorNamePipe } from './tools/lookup-debitor-name.pipe';
import { LookupSurfaceNamePipe } from './tools/lookup-surface-name.pipe';
import { LookupStateNamePipe } from './tools/lookup-state-name.pipe';
import { LookupAttachmentTypePipe } from './tools/lookup-attachment-type.pipe';
import { LookupAttachmentCategoryPipe } from './tools/lookup-attachment-category.pipe';
import { LookupEmailPipe } from './tools/lookup-user-email.pipe';
import {PermissionsModule} from '../auth/permissions/permissions.module';

@NgModule({
  declarations: [
    SidebarComponent,
    PopupFooterComponent,
    LookupUserNamePipe,
    LookupEmailPipe,
    LookupDebitorNamePipe,
    LookupSurfaceNamePipe,
    LookupStateNamePipe,
    LookupAttachmentTypePipe,
    LookupAttachmentCategoryPipe
  ],
  imports: [
    SidebarModule,
    PanelMenuModule,
    ButtonModule,
    MenuModule,
    CommonModule,
    DialogModule,
    PermissionsModule
  ],
  exports: [
    SidebarComponent,
    PopupFooterComponent,
    LookupUserNamePipe,
    LookupEmailPipe,
    LookupStateNamePipe,
    LookupDebitorNamePipe,
    LookupAttachmentCategoryPipe,
    LookupAttachmentTypePipe
  ],
  providers: []
})
export class SharedModule { }
