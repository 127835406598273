<div class='layout-wrapper'>
  <div class='layout-content-wrapper'>
    <div class='layout-topbar' [ngClass]='{ hiddenNav: !menuActive }'>
      <div class='topbar-left'>
        <a class='menu-button' (click)='onMenuButtonClick()'>
          <i [class]="menuActive ? 'pi pi-chevron-left' : 'pi pi-chevron-right'"></i>
        </a>
        <img
          id='logo-mobile'
          alt='ziselsberger-logo'
          class='mobile-logo'
          src='assets/images/logo.svg'
        />
      </div>
      <div class='topbar-right' [ngClass]='{ user: menuActive }'>
        <p-dropdown [options]='items' optionLabel='value'>
          <ng-template pTemplate='selectedItem'>
            <i class='pi pi-user'></i> {{ username }}
          </ng-template>
          <ng-template let-item pTemplate='item'>
            <div (click)='logout()'>{{ item.value }}</div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div
      class='layout-mask'
      [ngClass]="{ 'layout-mask-active': menuActive }"
      (click)='onMaskClick()'
    ></div>

    <div class='layout-content' [ngClass]='{ hiddenNav: !menuActive }'>
      <router-outlet></router-outlet>
    </div>
  </div>
  <section class='container'>
    <aside>
      <app-sidebar [menuActive]='!menuActive'></app-sidebar>
    </aside>
  </section>
</div>
