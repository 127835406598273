import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { Message } from 'primeng/api';
import { PermissionsService } from '../permissions/permissions.service';
import { PermissionType } from '../permissions/permissions.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  msgs: Message[] = [];
  loginForm: UntypedFormGroup;
  submitted = false;
  isloading = false;
  termsDialogVisible: boolean = false;
  privacyDialogVisible: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, private permissionService: PermissionsService,
              private router: Router, private authService: AuthService) {
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      clientId: '',
    });
  }

  get formControls(): any {
    return this.loginForm.controls;
  }

  async login(): Promise<void> {
    this.submitted = true;
    this.isloading = true;
    const credentials: any = Object.assign(this.loginForm.value);
    const ok = await this.authService.authenticate(credentials);
    if (ok) {
      if (!this.permissionService.isGrantedAny()) {
        this.msgs = [{ severity: 'info', detail: 'Sie haben keinen Zugriffa' }];
        localStorage.clear();
      } else {
        await this.showDashboard();
      }
    } else {
      this.msgs = [{ severity: 'error', summary: 'Error', detail: 'ungültiger Benutzername oder Passwort' }];
    }
    this.isloading = false;
  }

  async showDashboard(): Promise<void> {
    await this.router.navigate(['']);
  }

  termsPopup() {
    this.termsDialogVisible = true;
  }

  privacyPopup() {
    this.privacyDialogVisible = true;
  }
}
