import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from '../auth.service';
import decode from 'jwt-decode';
import {PermissionsService} from '../permissions/permissions.service';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(public auth: AuthService, private permissionsService: PermissionsService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    const token = localStorage.getItem('token');
    // decode the token to get its payload
    // const tokenPayload: any = decode(token);
    if (
      !this.auth.isAuthenticated() ||
      // tokenPayload.role !== expectedRole
      !this.permissionsService.isGranted(expectedRole)
    ) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

}
