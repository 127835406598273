import {environment} from '../../../environments/environment';

export const GlobalConstants = {
  host: environment.host,
  JWT: 'BAV_JWT'

};

export function api(path: string): string {
  return GlobalConstants.host + path;
}
