import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../../auth/auth.service';
import {PermissionsService} from '../../auth/permissions/permissions.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @Input() menuActive: boolean;

  constructor(private authService: AuthService, private permissionService: PermissionsService, private router: Router) { }

  ngOnInit(): void {
    if (this.permissionService.isGrantedConstructionFiles()) {
      this.router.navigate(['/dashboard/bauakten-liste']);
    } else {
      this.router.navigate(['/dashboard/debitoren']);
    }
  }
}
