<div [ngClass]="{ hideSideBar: menuActive }" class="layout-sidebar hideSideBar">
	<a [routerLink]="['/dashboard/bauakten-liste']" class="logo">
		<img alt="logo" src="assets/images/logo.svg" />
	</a>
	<div class="layout-menu">
		<div class="menu-items">
			<a *appGranted="'CAN_ADD_DEBITORS || CAN_EDIT_DEBITORS'" [routerLink]="['/dashboard/debitoren']" routerLinkActive="router-link-exact-active">DEBITOREN</a>
			<a *appGranted="'CAN_SEE_HISTORY || CAN_SEE_TOTAL_AMOUNT || CAN_ADD_CONSTRUCTION_FILES || IS_CONSTRUCTION_FILE_ADMIN'" [routerLink]="['/dashboard/bauakten-liste']" routerLinkActive="router-link-exact-active">BAUAKTEN</a>
      <a *appGranted="'CAN_SEE_HISTORY || CAN_SEE_TOTAL_AMOUNT || CAN_ADD_CONSTRUCTION_FILES || IS_CONSTRUCTION_FILE_ADMIN'" [routerLink]="['/dashboard/materials']" routerLinkActive="router-link-exact-active">MATERIALIEN</a>
			<a *appGranted="'IS_ADMIN'" [routerLink]="['/dashboard/benutzer']" routerLinkActive="router-link-exact-active">BENUTZER</a>
			<a *appGranted="'IS_ADMIN'" [routerLink]="['/dashboard/einstellungen']" routerLinkActive="router-link-exact-active">EINSTELLUNGEN</a>
			<a *appGranted="'IS_ADMIN'" [routerLink]="['/dashboard/locks']" routerLinkActive="router-link-exact-active">LOCKS</a>
		</div>
	</div>
</div>
