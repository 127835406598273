import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lookupSurfaceName'
})
export class LookupSurfaceNamePipe implements PipeTransform {

  transform(value: string, surfaces: any[]): string {
    if (!surfaces) {
      return '-';
    }
    const result = surfaces.filter((surface: any) => surface.surfaceId === value);
    if (!result || !result.length) {
      return '-';
    }
    return result[0].label;
  }

}
