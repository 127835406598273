import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lookupAttachmentCategory'
})
export class LookupAttachmentCategoryPipe implements PipeTransform {

  transform(value: any, categories: any[]): string {
    if (!categories) {
      return '-';
    }
    const result = categories.filter((category: any) => category.categoryId === value);
    if (!result || !result.length) {
      return '-';
    }
    return result[0].name;
  }

}
