import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lookupAttachmentType'
})
export class LookupAttachmentTypePipe implements PipeTransform {

  transform(value: any, types: any[]): string {
    if (!types) {
      return '-';
    }
    const result = types.filter((type: any) => type.typeId === value);
    if (!result || !result.length) {
      return '-';
    }

    return result[0].name;
  }

}
